<template>
  <div>
    <el-radio-group v-model="value" @change="changeVal">
      <el-radio-button label>不限</el-radio-button>
      <el-radio-button
        v-for="(item,index) in options"
        :key="index+item.codeName"
        :label="item.codeName"
      ></el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { getProductType } from '../../../api/product'
export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: '',
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: '',
      require: true,
    },
    queryName: {
      // 需要给后端的字段
      type: String,
    },
    name: {
      // 前端展示的字段
      type: String,
    },
  },
  watch: {
    code: function (newVal) {
      this.value = newVal
    },
  },
  data() {
    return {
      options: [],
      value: '',
    }
  },
  created() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
      const { data: res } = await getProductType({ code: this.typeCode })

      this.options = res

      if (this.code) {
        this.options.forEach((item) => {
          if (item.codeName === this.code) {
            this.value = item.codeName
          }
        })
      }
    },

    changeVal(val) {
      this.$emit('onChange', val, this.queryName, this.name)

      this.$emit('update:code', val)
    },
  },
}
</script>

<style>
</style>
