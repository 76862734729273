<template>
  <div>
    <el-checkbox-group v-model="value" @change="changeVal">
      <el-checkbox v-for="(item,index) in options" :key="index+'only'" :label="item.codeName"></el-checkbox>
    </el-checkbox-group>
    <div class="children">
      <div>
        <span v-if="options&&childrenOptions.length">子标签：</span>
      </div>
      <el-checkbox-group
        v-model="childrenValue"
        v-if="childrenOptions.length&&options[0].hasOwnProperty('childNode')"
        @change="changeChildrenVal"
        style="background-color: #F5F6F7;"
      >
        <el-checkbox v-for="(el,i) in childrenOptions" :key="i+'only'" :label="el.codeName"></el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import {
  getKnowTechList,
  getIndustryList,
  getKnowFieldList,
  getDicRadioButton,
} from '@/api/common'
import { getProductType } from '../../../api/product'
export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: '',
    },
    code: {
      // 选中的那一项的key
      type: Array,
      // default: () => [],
    },
    tag: {
      type: String,
    },
  },
  data() {
    return {
      options: [],
      childrenOptions: [],
      value: [],
      childrenValue: [],
      currentOrg: {},
    }
  },
  watch: {
    code: function (newVal) {
      if (!newVal.length) {
        this.clear()
      }
    },
  },
  created() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
      let res

      if (this.tag) {
        switch (this.tag) {
          case '产品':
            res = await getProductType({ code: '021' })
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/codeName/g, 'codeName')
            )
            break
          case '驱动':
            res = await getProductType({ code: '019' })
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/codeName/g, 'codeName')
            )
            break
          case '交付':
            res = await getProductType({ code: '008' })
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/codeName/g, 'codeName')
            )
            break
          case '行业':
            res = await getIndustryList()
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/industryName/g, 'codeName')
            )
            break
          case '领域':
            res = await getKnowFieldList()
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/fieldName/g, 'codeName')
            )
            break
          case '技术':
            res = await getKnowTechList()
            this.options = res.data
            this.options = JSON.parse(
              JSON.stringify(this.options).replace(/techName/g, 'codeName')
            )
            break
          default:
            break
        }
      } else {
        res = await getDicRadioButton({ code: this.typeCode })

        this.options = res.data
      }

      if (this.code.length) {
        this.code.forEach((item) => {
          this.options.forEach((el, index) => {
            if (el.codeName === item) {
              this.value.push(item)
            }
          })
        })

        this.changeVal(this.value)

        let arr = this.code.filter((item) => {
          return this.value.indexOf(item) === -1
        })

        this.childrenValue = [...new Set(arr)]

        this.changeChildrenVal(this.childrenValue)
      }
    },

    changeVal(val) {
      let optionChildrenArray = []

      this.childrenOptions = []

      this.changeChildrenVal(this.childrenOptions)
      this.$emit('update:code', val)

      this.options.forEach((item) => {
        val.forEach((el) => {
          if (item.codeName === el) {
            this.childrenOptions = this.childrenOptions.concat(item.childNode)

            if (item.hasOwnProperty('childNode')) {
              item.childNode.forEach((e) => {
                optionChildrenArray.push(e.codeName)
              })
            }
          } else {
            return false
          }
        })
      })

      // 取两个数组的交集
      let intersection = optionChildrenArray.filter((v) =>
        this.childrenValue.includes(v)
      )

      this.childrenValue = intersection

      this.$emit('updateCode', this.childrenValue, this.tag)

      this.childrenOptions = [...new Set(this.childrenOptions)]
    },

    changeChildrenVal(val) {
      this.$emit('updateCode', val, this.tag)
    },

    clear() {
      this.value = []

      this.childrenValue = []
    },
  },
}
</script>

<style lang="less" scoped>
.children {
  background-color: #f5f6f7;
  display: flex;
}
span {
  color: #606266 !important;
  padding-left: 20px;
  display: inline-block;
  min-width: 60px;
}
</style>
