<template>
  <div class="product">
    <el-form
      :model="queryInfo"
      class="product_form ly-header__bg"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="日期：" class="year_moun">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <!-- <el-form-item label="行业类别：">
        <dic-product-tag
          :code.sync="queryInfo.solutionIndustryName"
          query-name="solutionIndustryName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        ></dic-product-tag>
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-product-tag
          :code.sync="queryInfo.domainCategoryName"
          query-name="domainCategoryName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        ></dic-product-tag>
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-product-tag
          :code.sync="queryInfo.solutionTechnologyName"
          query-name="solutionTechnologyName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        ></dic-product-tag>
      </el-form-item> -->

      <el-form-item label="产品类型：">
        <dic-product-tag
          :code.sync="queryInfo.productTypeName"
          query-name="productTypeName"
          name="产品类型"
          tag="产品"
          @onChange="onChange"
        ></dic-product-tag>
        <!-- <dic-radio-button
          :code.sync="queryInfo.productTypeName"
          type-code="002"
          query-name="productTypeName"
          name="产品类型"
          @onChange="onChange"
        />-->
      </el-form-item>
      <el-form-item label="技术驱动：">
        <dic-radio-button
          :code.sync="queryInfo.productAi"
          type-code="019"
          query-name="productAi"
          name="技术驱动"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="交付类型：">
        <dic-radio-button
          :code.sync="queryInfo.solutionDelivery"
          type-code="008"
          query-name="solutionDelivery"
          name="交付类型"
          @onChange="onChange"
        />
      </el-form-item>
      <!-- 新增筛选项： 赋能行业 -->
      <el-form-item label="赋能行业：">
        <dic-tag-button
          :code.sync="queryInfo.industryName"
          query-name="industryName"
          name="赋能行业"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.field)"
        >
          {{ item.name }}：{{
            queryInfo[item.field] ? queryInfo[item.field] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>
    <div class="product_table">
      <div class="product_col">
        <div class="product_button">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addProduct"
            class="add"
            >添加</el-button
          >
          <!-- 数据上传 -->
          <import-data @fileClick="fileClick" moduleName="产品数据" />
          <!-- <upload-file-product /> -->
          <el-button @click="deleteBatch">
            批量删除
            <i class="jr-iconjr-icon-delect"></i>
          </el-button>
          <div class="upload_time">
            <div @click="caretText" class="caretTime">按上传时间</div>
            <div class="upload_time_icon">
              <!-- creation_time -->
              <i
                class="el-icon-caret-top"
                :class="[
                  queryInfo.columnName == 'create_time' && queryInfo.order == 0
                    ? 'isActiveTime'
                    : 'iconColor'
                ]"
                @click="caretTop"
              />
              <i
                class="el-icon-caret-bottom"
                :class="[
                  queryInfo.columnName == 'create_time' && queryInfo.order == 1
                    ? 'isActiveTime'
                    : 'iconColor'
                ]"
                @click="caretBottom"
              />
            </div>
          </div>
        </div>
        <el-input
          placeholder="请输入产品或企业名称"
          v-model="queryInfo.queryConditions"
          class="lager-input"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        style="width: 100%"
        :default-sort="{ prop: 'tableData', order: 'descending' }"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        @cell-dblclick="tableDoubleClick"
        class="table ly_table"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productCode"
          align="center"
          label="序号"
          width="80"
        >
          <template v-slot="scope">
            <span>
              {{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                  (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataIntegrity"
          label="数据完整度"
          align="center"
          width="120"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <div class="curpotion" @click="editItem(row)">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity ? row.dataIntegrity : 0"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" align="center">
          <template v-slot="{ row }">
            <span class="curpotion" @click="editItem(row)">
              {{ row.productName }}
            </span>
          </template>
        </el-table-column>
        <!-- 新增筛选项： 赋能行业 -->
        <el-table-column
          prop="industryCategory1"
          label="赋能行业"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.industryCategory1 !== null &&
                  scope.row.industryCategory1 !== '' &&
                  scope.row.industryCategory1
              "
            >
              <el-popover
                placement="bottom-start"
                width="200"
                trigger="hover"
                :content="scope.row.industryCategory1"
              >
                <span slot="reference">
                  {{ scope.row.industryCategory1.substring(0, 20) }}
                  <span v-if="scope.row.industryCategory1.length > 20"
                    >...</span
                  ></span
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="solutionIndustry"
          label="行业类别"
          align="center"
          v-if="false"
        > -->
        <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.solutionIndustry }}</span>
          </template> -->
        <!-- <template slot-scope="scope">
            <div
              v-if="
                scope.row.solutionIndustry !== null &&
                scope.row.solutionIndustry !== ''
              "
              class="tableColor"
            >
              {{ scope.row.solutionIndustry.substring(0, 20) }}
              <span v-if="scope.row.solutionIndustry.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="domainCategory"
          label="领域类别"
          align="center"
          v-if="false"
        > -->
        <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.domainCategory }}</span>
          </template> -->
        <!-- <template slot-scope="scope">
            <div
              v-if="
                scope.row.domainCategory !== null &&
                scope.row.domainCategory !== ''
              "
              class="tableColor"
            >
              {{ scope.row.domainCategory.substring(0, 20) }}
              <span v-if="scope.row.domainCategory.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="productType"
          label="产品类型"
          align="center"
          v-if="false"
        >
          <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.productType }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.productType !== null && scope.row.productType !== ''
              "
              class="tableColor"
            >
              {{ scope.row.productType.substring(0, 20) }}
              <span v-if="scope.row.productType.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyFullName"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          label="修改时间"
          sortable="custom"
          align="center"
          :formatter="formatTime"
        ></el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <!-- creationTime -->
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              content="删除"
              @buttonClick="deleteProuct(row)"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="dialogType"
      :visible.sync="dialogVisible"
      width="60%"
      @close="handleClose"
      center
    >
      <el-form :model="essentialInfor" label-width="100px">
        <el-form-item
          label="行业类别："
          prop="solutionIndustry"
          v-if="dialogType === '行业类别'"
        >
          <multiple-choice
            ref="checkboxEnterprise"
            :code.sync="essentialInfor.solutionIndustry"
            tag="行业"
            key="行业"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item
          label="领域类别："
          prop="domainCategory"
          v-else-if="dialogType === '领域类别'"
        >
          <multiple-choice
            ref="checkboxEnterprise"
            :code.sync="essentialInfor.domainCategory"
            tag="领域"
            key="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="产品类型：" prop="productType" v-else>
          <multiple-choice
            ref="checkboxEnterprise"
            :code.sync="essentialInfor.productType"
            tag="产品"
            key="产品"
            @updateCode="updateCode"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveItem">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ButtonTable from "../../components/ButtonTable.vue";
// import { URL } from "../../../config";
import {
  getProductList,
  deleteProduct,
  updateEssenti,
  downloadProductFile
} from "@/api/product.js";
import { pagination } from "../../mixins/pagination";
import DataTime from "./com/dataTime.vue";
import DicProductTag from "@/components/DicTagButton.vue";
import DicProductButton from "./com/dicProductButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import UploadFileProduct from "./com/uploadFileProduct.vue";
import multipleChoice from "./com/multipleChoice.vue";
import ImportData from "../../components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
export default {
  mixins: [pagination],
  data() {
    return {
      dialogType: "技术类别",
      dialogVisible: false,
      queryInfo: {
        columnName: "create_time,update_time",
        years: "",
        month: "",
        day: "",
        pageSize: 10,
        pageNum: 1,
        order: 1,
        queryConditions: "",
        solutionIndustry: [], //行业类别
        solutionIndustryName: "",
        domainCategory: [], // 领域类别
        domainCategoryName: "",
        solutionTechnology: [], // 技术类别
        solutionTechnologyName: "",
        productType: [], //产品类型
        productTypeName: "",
        productAi: "", //技术驱动
        solutionDelivery: "" //交付类型
      },
      essentialInfor: {
        solutionIndustry: [], //行业类别
        solutionIndustryChild: [],
        domainCategory: [], // 领域类别
        domainCategoryChild: [],
        productType: [], //产品类型
        productTypeChild: []
      },
      tableData: [],
      multipleTable: [],
      tags: [
        {
          name: "赋能行业",
          tagName: "industryCategoryStr",
          valLength: 0
        },
        // {
        //   name: "行业类别",
        //   field: "solutionIndustryName",
        //   valLength: 0,
        // },
        // {
        //   name: "领域类别",
        //   field: "domainCategoryName",
        //   valLength: 0,
        // },
        // {
        //   name: "技术类别",
        //   field: "solutionTechnologyName",
        //   valLength: 0,
        // },
        {
          name: "产品类型",
          field: "productTypeName",
          valLength: 0
        },
        {
          name: "技术驱动",
          field: "productAi"
        },
        {
          name: "交付类型",
          field: "solutionDelivery"
        }
      ],
      multipleFile: [],
      valueI: 0
    };
  },
  created() {
    this.search();
  },
  mounted() {
    this.queryInfo.years = "";
    this.queryInfo.month = "";
    this.queryInfo.day = "";
    this.search();
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  methods: {
    fileClick() {
      this.search();
    },
    //下载产品附件
    async downloadClick(row) {
      const URL = "http://175.24.131.212:9506/";
      // const URL = "http://192.168.0.192:8086/";
      if (row.productFile) {
        const productFiles = row.productFile.substring(
          row.productFile.lastIndexOf("/") + 1
        );
        axios({
          method: "GET",
          url: `${URL}system/public/downloadUrlFile`,
          params: { urlStr: "http://" + row.productFile },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization
          }
        })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/msword"
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = productFiles + ".pdf"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch(err => {
            //this.$message.error('文件下载失败')
          });
      } else {
        this.$message("该条数据没有附件！");
      }

      // 前辈写的的附件下载
      // const res = await downloadProductFile({ productCode: row.productCode });
      // if (res.code == 200) {
      //   const arr = [];
      //   this.multipleFile = res.data;
      //   this.multipleFile.forEach((el) => {
      //     arr.push(el.productFileKey);
      //   });
      //   const paramsurl = arr.join(",");
      //   // const URL = 'http://192.168.0.6:8085/'
      //   axios({
      //     method: "POST",
      //     url: `${URL}/public/downZip`,
      //     params: { urlList: paramsurl },
      //     responseType: "blob",
      //     headers: {
      //       // 设置请求头
      //       Authorization: this.Authorization,
      //     },
      //   })
      //     .then((res) => {
      //       let blob = new Blob([res.data], {
      //         type: "application/zip",
      //       });
      //       let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
      //       const a = document.createElement("a"); //创建a标签
      //       a.style.display = "none";
      //       a.href = href; // 指定下载'链接
      //       a.download = "数据系统产品附件.zip"; //指定下载文件名
      //       a.click(); //触发下载
      //       URL.revokeObjectURL(a.href); //释放URL对象
      //       document.body.removeChild(a); //释放标签
      //     })
      //     .catch((err) => {
      //       //this.$message.error('文件下载失败')
      //     });
      // }
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 弹窗修改
    async saveItem() {
      //行业
      if (this.essentialInfor.solutionIndustryChild) {
        this.essentialInfor.solutionIndustry = this.essentialInfor.solutionIndustry.concat(
          this.essentialInfor.solutionIndustryChild
        );
      }
      //领域
      if (this.essentialInfor.domainCategoryChild) {
        this.essentialInfor.domainCategory = this.essentialInfor.domainCategory.concat(
          this.essentialInfor.domainCategoryChild
        );
      }
      //产品
      if (this.essentialInfor.productTypeChild) {
        this.essentialInfor.productType = this.essentialInfor.productType.concat(
          this.essentialInfor.productTypeChild
        );
      }
      this.essentialInfor.solutionIndustry = [
        ...new Set(this.essentialInfor.solutionIndustry)
      ];
      this.essentialInfor.domainCategory = [
        ...new Set(this.essentialInfor.domainCategory)
      ];
      this.essentialInfor.productType = [
        ...new Set(this.essentialInfor.productType)
      ];
      const res = await updateEssenti(this.essentialInfor);

      if (res.code === 200) {
        this.$message.success("编辑成功");
        this.dialogVisible = false;
        this.search();
      } else {
        this.$message.error("编辑失败");
      }
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;
      this.$refs.checkboxEnterprise.clear();
    },
    updateCode(val, tag) {
      this.$nextTick(() => {
        switch (tag) {
          case "行业":
            this.essentialInfor.solutionIndustryChild = val;
            break;

          case "领域":
            this.essentialInfor.domainCategoryChild = val;
            break;

          case "产品":
            this.essentialInfor.productTypeChild = val;
            break;

          default:
            break;
        }
      });
    },
    // 双击单元格
    tableDoubleClick(row, column) {
      if (
        column.label === "产品类型" ||
        column.label === "行业类别" ||
        column.label === "领域类别"
      ) {
        Object.assign(this.essentialInfor, row);
        //产品类型转数组
        // this.essentialInfor.productType = this.formatString(
        //   this.essentialInfor.productType
        // );
        //行业字符串转数组
        // this.essentialInfor.solutionIndustry = this.formatString(
        //   this.essentialInfor.solutionIndustry
        // );
        //领域字符串转数组
        // this.essentialInfor.domainCategory = this.formatString(
        //   this.essentialInfor.domainCategory
        // );
        if (column.label === "产品类型") {
          this.dialogType = "产品类型";
        } else if (column.label === "行业类别") {
          this.dialogType = "行业类别";
        } else if (column.label === "领域类别") {
          this.dialogType = "领域类别";
        }
        this.dialogVisible = true;
        if (this.$refs.checkboxEnterprise) {
          this.$refs.checkboxEnterprise.getDicData();
        }
      }
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);
      this.queryInfo[name] = "";
      this.search();
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      // 解决筛选条件后点击第二条，换筛选条件后还在第二条问题
      this.queryInfo.pageNum = 1;
      let childrenVal = [];
      if (!this.tags.some(item => item.field === queryName)) {
        if (
          name === "产品类型" ||
          name === "行业类别" ||
          name === "领域类别" ||
          name === "技术类别" ||
          name === "赋能行业"
        ) {
          this.tags.push({
            name,
            field: queryName,
            valLength: 0
          });
        } else {
          this.tags.push({
            name,
            field: queryName
          });
        }
      }
      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex(el => {
        return el.name === name;
      });
      switch (name) {
        case "产品类型":
          this.queryInfo.productType = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.productType = this.queryInfo.productType.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "行业类别":
          this.queryInfo.solutionIndustry = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.solutionIndustry = this.queryInfo.solutionIndustry.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "领域类别":
          this.queryInfo.domainCategory = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.domainCategory = this.queryInfo.domainCategory.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "技术类别":
          this.queryInfo.solutionTechnology = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.solutionTechnology = this.queryInfo.solutionTechnology.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "赋能行业":
          this.queryInfo.industryCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryCategoryList = this.queryInfo.industryCategoryList.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }

          break;
        default:
          break;
      }
      this.search();
    },
    // 父页面添加 日期改变的方法
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    // 点击文字切换正倒序
    caretText() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "create_time,update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    // 正序
    caretTop() {
      // this.queryInfo.order = 1;
      // this.search();
    },
    // 倒序
    caretBottom() {
      // this.queryInfo.order = 0;
      // this.search();
    },
    //批量删除
    deleteBatch() {
      if (this.multipleTable.length < 1) {
        this.$message({
          type: "warning",
          message: "请选择需要删除的数据项"
        });
        return;
      }
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteProduct(this.multipleTable);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    //勾选
    handleSelectionChange(val) {
      let arr = [];
      val.forEach(item => {
        arr.push(item.productCode);
      });
      this.multipleTable = arr;
    },
    //删除产品数据
    deleteProuct(row) {
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteProduct([row.productCode]);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    //编辑页面
    editItem(row) {
      let text = this.$router.resolve({
        path: "/productInfo",
        query: {
          productCode: row.productCode, // 每条数据唯一id
          enterpriseType: 1
          // queryInfo: this.queryInfo,
        }
      });
      window.open(text.href, "_blank");
      // this.$router.push({
      //   name: 'ProductInfo',
      //   params: {
      //     productCode: row.productCode,
      //     enterpriseType: 1,
      //   },
      // })
    },
    //路由跳转到新增产品信息
    addProduct() {
      this.$router.push({
        name: "ProductInfo",
        params: {
          productCode: "", // 表格序号
          enterpriseType: 0
        }
      });
    },
    //表格默认数据
    async search() {
      const res = await getProductList(this.queryInfo);
      if (res.code === 200) {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.tableData.forEach(el => {
          if (el.dataIntegrity) {
            // el.dataIntegrity = Number(el.dataIntegrity.slice(0, -1));
          } else {
            el.companyDataIntegrity = 0;
          }
        });
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,update_time";
        }
      } else if (column.prop === "updateTime") {
        this.queryInfo.columnName = "update_time";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,update_time";
        }
      }
      this.search();
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (
        column.label === "领域类别" ||
        column.label === "行业类别" ||
        column.label === "产品类型"
      ) {
        return "cursor:pointer";
      }
    }
  },
  components: {
    ButtonTable,
    DataTime,
    DicProductTag,
    DicProductButton,
    UploadFileProduct,
    multipleChoice,
    ImportData,
    DicRadioButton,
    DicTagButton
  }
};
</script>

<style lang="less" scoped>
.product {
  width: 100%;
  overflow: hidden;

  .tableColor {
    cursor: pointer;
  }

  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }

  /deep/ .el-form-item__content {
    margin-left: 100px !important;
  }

  .year_moun {
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  /deep/ .el-button {
    margin-right: 10px;
  }

  .product_col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .product_form {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
  }
  .year_moun {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .page {
    margin-top: 20px;
  }
  .product_table {
    width: 100%;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 16px 20px;
    overflow: hidden;
    background-color: #fff;
    .caretTime:hover {
      cursor: pointer;
    }
    .product_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      .upload_time {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0 20px;
        .upload_time_icon {
          .isActiveTime {
            color: #1e9fff !important;
          }
        }
        .el-icon-caret-top,
        .el-icon-caret-bottom {
          position: absolute;
          right: -16px;
          color: #c0c4cc;
        }
        .el-icon-caret-top {
          top: 0px;
        }
        .el-icon-caret-bottom {
          top: 6px;
        }
      }
    }
    .table {
      margin-top: 20px;
      .red {
        color: #fd4b4b;
      }
    }
  }

  .curpotion {
    cursor: pointer;
  }
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
.iconColor {
  color: #c0c4cc !important;
}
</style>
